import { t } from "i18next";
import React from "react";

const ProductCircle = ({ item }) => {
  return (
    <div className="flex flex-col items-center lg:w-28 lg:h-28 w-20 h-20 bg-product-cir justify-evenly rounded-full">
      <p className="text-xl font-medium text-[#777777] ">{t(item?.key)}</p>
      <p className="text-xl font-medium text-white ">{item?.value} {t("CM")}</p>
    </div>
  );
};

export default ProductCircle;
