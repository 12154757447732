import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { newBaseUrl } from "../Consts";
export const geyProductByCategory = createAsyncThunk(
  `productsByCategory/getProductsByCategory`,
  async (id, thunkApi) => {
    try {
      const lang = localStorage.getItem("lang");
      const location = localStorage.getItem("location");
      const res = await axios.get(
        `${newBaseUrl}${lang}/api/Site/categories/${id}/${location}/products`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      return res.data;
    } catch (error) {
      console.log(error);
    }
  }
);
const ProductsByCategory = createSlice({
  name: "productsByCategory",
  initialState: { productsByCategory: [], loading: false, error: null },
  extraReducers: {
    [geyProductByCategory.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [geyProductByCategory.fulfilled]: (state, action) => {
      state.productsByCategory = action.payload;
      state.loading = false;
      state.error = null;
    },
    [geyProductByCategory.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.action;
    },
  },
});

export default ProductsByCategory.reducer;
