import { t } from "i18next";
import React from "react";
// import { FaFacebookF } from "react-icons/fa";
// import { AiOutlineTwitter, AiFillLinkedin } from "react-icons/ai";
// import { BsInstagram } from "react-icons/bs";
import { Link } from "react-router-dom";
const PowerdBy = () => {
  // const icons = [
  //   { icon: <FaFacebookF /> },
  //   { icon: <AiOutlineTwitter /> },
  //   { icon: <AiFillLinkedin /> },
  //   { icon: <BsInstagram /> },
  // ];
  return (
    <div className="flex justify-between flex-col lg:flex-row px-4 py-2">
      <p className=" text-black text-xs md:text-sm lg:text-base font-normal">
        {t("Copyright © Nardi Website- Powered by")}{" "}
        <Link
          to="https://aiwagroup.org/"
          target="_blank"
          className="text-newOrange"
        >
          {t("AIWA GROUP")}
        </Link>
      </p>
      {/* <div className="flex items-center justify-center gap-3">
        {icons.map((icon, i) => (
          <Link className="text-black font-normal text-lg" key={i}>
            {icon.icon}
          </Link>
        ))}
      </div> */}
    </div>
  );
};

export default PowerdBy;
