import React from "react";
import { newBaseUrlImg } from "../../Consts";

const AboutSection = ({ img, reverse, data, title }) => {
  return (
    <div className="shadow-productBarShadow">
      <div
        className={`flex items-center justify-center ${
          reverse ? "lg:flex-row-reverse flex-col" : "flex-col lg:flex-row"
        } w-[100vw] py-5 lg:py-10 lg:gap-7 shadow-about-card`}
      >
        <img
          src={`${newBaseUrlImg}${img}`}
          alt={img}
          className="lg:w-[30vw] w-full h-[44vh] md:h-[20vh] lg:h-[45vh]  object-contain rounded-3xl"
        />

        <div className="flex items-start gap-2 lg:gap-4 flex-col w-full lg:w-[50%] px-3">
          <h2 className="lg:text-4xl text-2xl text-black font-semibold">
            {title}
          </h2>
          <p className="lg:text-xl text-base font-light text-[#777777] ">
            {data}
          </p>
        </div>
      </div>
    </div>
  );
};

export default AboutSection;
