import React, { useCallback, useEffect, useState } from "react";
import BlueBg from "../Components/BlueBg";
import ProductLeftFilterBar from "../Components/ProductPageComponent/ProductLeftFilterBar";
import { useParams } from "react-router-dom";
import { geyProductByCategory } from "../api/ProductsByCategory";

import { useDispatch, useSelector } from "react-redux";
import ProductBodyByCat from "../Components/ProductByCat/ProductBodyByCat";
import Loading from "../Components/Loading";
import { Helmet } from "react-helmet";
const ProductsByCategoryPage = () => {
  const [show, setShow] = useState(false);
  const dispatch = useDispatch();
  const id = useParams().id;
  const fetchData = useCallback(() => {
    dispatch(geyProductByCategory(id));
  }, [dispatch, id]);
  useEffect(() => {
    fetchData();
  }, [fetchData]);
  const categoryData = useSelector(
    (state) => state?.CategorySlice?.category?.resulte || []
  );
  const productsData = useSelector(
    (state) => state?.ProductsCatSlice?.productsByCategory || []
  );
  const loading = useSelector((state) => state?.ProductsCatSlice?.loading);
  // console.log(loading);
  // const load = true;
  useEffect(() => {
    console.log("SEO Content:", categoryData?.seo);
  }, [categoryData]);
  return (
    <div className="bg-white overflow-hidden">
      <BlueBg />
      {/* <Helmet>
        <meta name="description" content={categoryData.seo} />
        <meta property="og:title" content={categoryData.name} />
        <meta property="og:description" content={categoryData.description} />
      </Helmet> */}
      <div className=" w-full flex   pl-3 lg:pt-[28rem] pb-[1rem] overflow-auto  lg:gap-7">
        <ProductLeftFilterBar
          setShow={setShow}
          show={show}
          categoryData={categoryData}
        />
        {loading ? (
          <div className=" flex items-center w-full justify-center lg:w-[75vw] h-[80vh] ">
            <Loading
              type={"spinningBubbles"}
              color={"#EA5C24"}
              height={30}
              width={30}
            />
          </div>
        ) : (
          <ProductBodyByCat
            setShow={setShow}
            show={show}
            productsData={productsData}
          />
        )}
      </div>
    </div>
  );
};

export default ProductsByCategoryPage;
