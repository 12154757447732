import React from "react";
import NewImg from "../../assets/newsing.png";
import { BsArrowRight } from "react-icons/bs";
import { newBaseUrlImg } from "../../Consts";
import moment from "moment/moment";
const NewsCard = ({ show, setShow, newitem, setPopUpData }) => {
  return (
    <div className="flex items-center justify-center  px-4 ">
      <div className="flex lg:items-start items-start flex-col justify-center w-[90vw]  lg:w-[20vw] h-[54vh] md:h-[38vh] lg:h-[55vh]  gap-2 shadow-productBarShadow pb-2">
        <img
          src={`${newBaseUrlImg}${newitem?.image}`}
          alt={NewImg}
          className="w-full h-[30vh] object-cover rounded-t-lg"
        />
        <p className="font-light text-sm text-[#777777] h-[20vh] ">
          {newitem?.content.substring(0, 250)}.......
        </p>
        <div className="flex items-center w-full justify-between px-3">
          <div className="flex flex-col items-center justify-center w-14 h-14 bg-newOrange rounded-xl">
            <p className="text-white text-base font-medium">
              {moment(newitem?.created_at).format("DD")}
            </p>
            <p className="text-white text-base font-medium">
              {moment(newitem?.created_at, "DD-MM-YYYY").format("MMM")}
            </p>
          </div>
          <button
            className="flex items-center justify-center gap-1 text-base font-semibold "
            onClick={() => {
              setPopUpData(newitem);
              setShow(!show);
            }}
          >
            Read More <BsArrowRight />
          </button>
        </div>
      </div>
    </div>
  );
};

export default NewsCard;
