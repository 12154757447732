import React from "react";

const HomeContant = ({ data, reverse }) => {
  return (
    <div
      className={`flex md:flex-row lg:flex-col ${
        reverse
          ? "lg:flex-col-reverse md:flex-row-reverse flex-col"
          : "flex-col "
      }`}
    >
      <div className="lg:w-[32vw] md:w-[500px] w-full lg:h-[450px] md:h-[450px] flex flex-col items-start justify-start gap-4 lg:pl-7 pl-2 lg:pt-24 md:pt-16 pt-10 pb-4  bg-[#E2E2E2]">
        <h2 className="text-[#A0A0A0] text-5xl font-thin px-1">{data.name}</h2>
        <span className="text-newOrange text-xs md:text-sm lg:text-base font-medium px-1 mt-[-1rem]">
          {data.title}
        </span>
        <p className="font-normal text-[#292929] md:w-[90%] mt-[-.4rem] px-1">{data.content}</p>
      </div>
      <img
        src={data.img}
        loading="lazy"
        alt={data.img}
        className="md:w-[440px] lg:w-[32vw] h-[450px] object-cover"
      />
    </div>
  );
};

export default HomeContant;
