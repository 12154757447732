import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { newBaseUrl } from "../Consts";
export const subscirbeMail = createAsyncThunk(
  `subscribe/sunscribe`,
  async (data, thunkApi) => {
    try {
      const res = await axios.post(`${newBaseUrl}Site/EmailUs-store`, data, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      return res.data;
    } catch (error) {
      console.log(error);
    }
  }
);
const subscribeSlice = createSlice({
  name: "subscirbeMail",
  initialState: { subscirbe: [], loading: false, error: null },
  extraReducers: {
    [subscirbeMail.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [subscirbeMail.fulfilled]: (state, action) => {
      state.subscirbe = action.payload;
      state.loading = false;
      state.error = null;
    },
    [subscirbeMail.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.action;
    },
  },
});

export default subscribeSlice.reducer;
