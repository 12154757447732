import React, { useState } from "react";
import Logo from "../assets/nardi-logo.png";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import { FaFacebookF } from "react-icons/fa";
import { AiOutlineTwitter, AiFillLinkedin } from "react-icons/ai";
import { BsInstagram } from "react-icons/bs";
import HoverNavBar from "./HoverNavBar";
import Select from "react-select";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { getLang } from "../api/LangReducer";
const NavBar = () => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const [show, setShow] = useState(false);
  const [activeLink, setActiveLink] = useState(""); // New state variable
  const langs = useSelector((state) => state?.LangReducer?.lang?.langs || []);
  console.log("langs", langs);
  const optionslang = langs?.map((item) => ({
    value: item?.code,
    label: item?.name,
  }));
  const location = useLocation();
  const navigate = useNavigate();
  const StorageLang = localStorage.getItem("lang");
  const [selectedOption, setSelectedOption] = useState("");
  useEffect(() => {
    dispatch(getLang());
  }, []);
  useEffect(() => {
    const newLang = optionslang?.find((item) => item?.value === StorageLang);
    console.log("newLang", newLang);
    if (newLang) {
      setSelectedOption(newLang);
    }
  }, [langs, StorageLang]);

  const icons = [
    {
      icon: <FaFacebookF />,
      ref: "https://www.facebook.com/profile.php?id=61550992055382&mibextid=ZbWKwL",
    },
    { icon: <AiOutlineTwitter />, ref: "" },
    { icon: <AiFillLinkedin />, ref: "" },
    {
      icon: <BsInstagram />,
      ref: "https://www.instagram.com/nardi.elettrodomestici/?img_index=3",
    },
  ];
  const showLogo = location.pathname === "/";

  const toggleNavbar = () => {
    setShow(!show);
    setActiveLink(""); // Reset the active link when toggling the navbar
    console.log('activelink', activeLink)
  };
  const customStyles = {
    control: (base, state) => ({
      ...base,
      borderColor: state.isFocused ? "none" : "none",
      backgroundColor: "#EA5C24",
      color: "#fff",
    }),
    option: (base, state) => ({
      ...base,
      borderColor: state.isFocused ? "none" : "none",

      backgroundColor: state.isFocused ? "#EA5C24" : "#fff", // Change the background color when focused
      color: state.isFocused ? "#fff" : "#000", // Change the text color when focused
    }),
    singleValue: (base) => ({
      ...base,
      borderColor: "none",

      color: "#fff", // Change the text color for the selected option
    }),
  };
  const handleChange = async (selectedOption) => {
    // Reload the page first
    window.location.reload();

    // Set the selected language and update body direction (this will happen after the reload)
    setSelectedOption(selectedOption);
    const selectedLanguage = selectedOption.label;
    console.log("first", selectedOption.label);
    if (selectedLanguage === "Arabic") {
      document.body.dir = "rtl";
    } else {
      document.body.dir = "ltr";
    }
    await localStorage.setItem("lang", selectedOption.value);
    await i18n.changeLanguage(selectedLanguage);
  };
  return (
    <>
      <div className="w-[80%] h-[10vh] p-3 bg-[#E8E8E8] flex  items-center justify-between m-auto mt-8 z-50">
        {showLogo && (
          <img
            src={Logo}
            alt="Nardi-Logo"
            onClick={() => navigate("/")}
            className="w-[9rem] h-[3rem] object-contain cursor-pointer"
          />
        )}
        <div className="flex items-center justify-center gap-7">
          <NavLink
            onClick={() => setShow(false)}
            to={"/"}
            className={({ isActive }) =>
              isActive
                ? "text-newOrange underline underline-offset-1 text-base font-medium"
                : "text-base font-medium"
            }
          >
            {t("Home")}
          </NavLink>
          <button
            // to={"/products"}
            onClick={() => toggleNavbar()}
            className={"text-base font-medium  "}
          >
            {t("Products")}
          </button>
          <NavLink
            onClick={() => setShow(false)}
            to={"news"}
            className={({ isActive }) =>
              isActive
                ? "text-newOrange underline underline-offset-1 text-base font-medium"
                : "text-base font-medium"
            }
          >
            {t("News")}
          </NavLink>
          <NavLink
            to={"about"}
            onClick={() => setShow(false)}
            className={({ isActive }) =>
              isActive
                ? "text-newOrange underline underline-offset-1 text-base font-medium"
                : "text-base font-medium"
            }
          >
            {t("About")}
          </NavLink>
          <NavLink
            onClick={() => setShow(false)}
            to={"contact"}
            className={({ isActive }) =>
              isActive
                ? "text-newOrange underline underline-offset-1 text-base font-medium"
                : "text-base font-medium"
            }
          >
            {t("Contact")}
          </NavLink>
        </div>

        <div className="flex items-center justify-center gap-3">
          {icons.map((icon, i) => (
            <Link
              className="text-newOrange font-normal text-lg"
              key={i}
              to={icon.ref}
              target="_aboutblank"
            >
              {icon.icon}
            </Link>
          ))}
          <div className="w-[8vw]">
            {/* <button className="bg-newOrange px-2 py-1 text-white rounded flex items-center justify-center text-base font-normal ">
              French <IoIosArrowDown />
            </button>
            <div className="absolute top-8 gap-0 flex flex-col ">
              <button className="bg-newOrange px-2 py-1 text-white rounded flex items-center justify-center text-base font-normal">
                English <IoIosArrowDown />
              </button>
              <button className="bg-newOrange px-2 py-1 text-white rounded flex items-center justify-center text-base font-normal">
                Arabic <IoIosArrowDown />
              </button> */}
            <Select
              styles={customStyles}
              defaultValue={selectedOption}
              value={selectedOption}
              onChange={handleChange}
              options={optionslang}
              isSearchable={false}
            />
          </div>
        </div>
      </div>
      <div
        className={`absolute ${
          showLogo
            ? "xl:left-[32vw] lg:left-[20vw] md:left-[18vw] "
            : "md:left-[15vw]"
        }  top-[107px] z-[1000] `}
        hidden={!show}
      >
        <HoverNavBar show={show} setShow={setShow} showLogo={showLogo} />
      </div>
    </>
  );
};

export default NavBar;
