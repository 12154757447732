import React, { useEffect, useState } from "react";
import BlueBg from "../Components/BlueBg";
import LeftCalender from "../Components/NewsComponent/LeftCalender";
import NewsCard from "../Components/NewsComponent/NewsCard";
import NewsPopUp from "../Components/NewsComponent/NewsPopUp";
import { useDispatch, useSelector } from "react-redux";
import Loading from "../Components/Loading";
import { getNews } from "../api/NewReducer";
import { Helmet } from "react-helmet";
import { t } from "i18next";
const News = () => {
  const [show, setShow] = useState(false);
  const [popUpData, setPopUpData] = useState(false);
  const [selectedDate, setSelectedDate] = useState("");
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getNews(selectedDate));
  }, [dispatch, selectedDate]);
  const data = useSelector((state) => state?.NewsSlice?.News || []);
  const loading = useSelector((state) => state?.NewsSlice?.loading);
  return (
    <div>
      <Helmet>
        <title>News</title>
        <meta name="description" content="News" />
        <meta property="og:title" content="News nardi News nardi " />
        <meta property="og:description" content="stove News stove." />
      </Helmet>
      <BlueBg />
      <div className="w-[100vw] lg:pt-[28rem] pb-[1rem] pt-6 items-center lg:items-start justify-center lg:gap-7 flex flex-col lg:flex-row ">
        <LeftCalender
          setSelectedDate={setSelectedDate}
          selectedDate={selectedDate}
        />
        <div className="lg:w-[70vw] w-full grid grid-cols-1  md:grid-cols-2 lg:grid-cols-3 gap-14  md:py-0 lg:py-1 lg:gap-6 relative">
          {data.length > 0 ? (
            data?.map((newitem, i) => (
              <NewsCard
                setShow={setShow}
                show={show}
                key={i}
                newitem={newitem}
                setPopUpData={setPopUpData}
              />
            ))
          ) : (
            <div className="flex items-center justify-center w-screen lg:w-[70vw] h-[40vh]">
              {loading ? (
                <Loading
                  type="spinningBubbles"
                  color="#EA5C24"
                  height={30}
                  width={30}
                />
              ) : (
                <p className="font-medium text-3xl text-newOrange">
                  {t("Stay Tuned...!")}
                </p>
              )}
            </div>
          )}
        </div>
      </div>
      <div hidden={show !== true}>
        <NewsPopUp setShow={setShow} show={show} popUpData={popUpData} />
      </div>
    </div>
  );
};

export default News;
