import React from "react";
import Banner from "../../assets/aboutbannerv.gif";
import Logo from "../../assets/nardi-logo.png";

const AboutBanner = ({ data }) => {
  return (
    <div className="w-[99vw] h-[50vh] lg:h-[60vh] md:h-[20vh] relative shadow-productBarShadow rounded-b-lg  ">
      <img
        src={Banner}
        alt={Banner}
        className="w-full h-full object-cover rounded-b-lg"
      />
      <div className="absolute right-0 bottom-0 w-full lg:h-[20vh] flex items-center justify-center flex-row lg:gap-8 bg-about-banner py-2 md:py-4 px-2 gap-4 lg:px-8 rounded-b-lg">
        <img
          src={Logo}
          alt={Logo}
          className="lg:w-[20vw] w-[25vw] lg:h-56 object-contain rounded-b-lg"
        />
        <p className="text-[10px] lg:text-base text-[#777777] w-full font-light">
          {data}
        </p>
      </div>
    </div>
  );
};

export default AboutBanner;
