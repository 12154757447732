import React, { useCallback, useEffect } from "react";
import BlueBg from "../Components/BlueBg";
import { MdArrowBackIosNew } from "react-icons/md";
import ProductByIdLeft from "../Components/ProductByIdComponents/ProductByIdLeft";
import ProductImages from "../Components/ProductByIdComponents/ProductImages";
import { useNavigate, useParams } from "react-router-dom";
import ProductData from "../Components/ProductByIdComponents/ProductData";
import { getProductById } from "../api/productById";
import { useDispatch, useSelector } from "react-redux";
import Loading from "../Components/Loading";
import { Helmet } from "react-helmet";

const Product = () => {
  const id = useParams().id;
  const dispatch = useDispatch();
  const fetchData = useCallback(() => {
    dispatch(getProductById(id));
  }, [dispatch, id]);
  useEffect(() => {
    fetchData();
  }, [fetchData]);
  const navigate = useNavigate();
  const product = useSelector(
    (state) => state?.ProductId?.productId?.product || []
  );
  const productLoading = useSelector((state) => state?.ProductId?.loading);

  // useEffect(() => {
  //   console.log("SEO Content:", product?.seo);
  // }, [product]);
  return (
    <div className="">
      <Helmet>
        <title>{product.name}</title>
        <meta name="description" content={product.seo} />
        <meta property="og:title" content={product.name} />
        <meta property="og:description" content={product.description} />
      </Helmet>
      <BlueBg />
      {productLoading ? (
        <div className=" w-full lg:w-[100vw]  flex items-center justify-center lg:pt-[25rem] h-[100vh]">
          <Loading
            type="spinningBubbles"
            color="#EA5C24"
            height={30}
            width={30}
          />
        </div>
      ) : (
        <>
        {console.log('product', product)}
          <div className=" w-full flex pt-4  lg:pl-8 lg:pt-[28rem] pb-[1rem] flex-col gap-3  lg:gap-7 bg-white bg-productBarShadow ">
            <div className="flex items-center gap-1 pl-2  lg:pl-10">
              <button onClick={() => navigate(-1)}>
                <MdArrowBackIosNew className="text-2xl text-newOrange" />
              </button>
              <p className="text-base font-semibold text-black">
                {product?.model_no}
              </p>
            </div>
            <div className="flex items-center justify-center gap-[1rem] lg:gap-[8rem] flex-col lg:flex-row  w-[100vw]">
              <ProductByIdLeft product={product} />
              {product.images && (
                <ProductImages images={product?.images} product={product} />
              )}
            </div>
          </div>
          <ProductData product={product} />
        </>
      )}
    </div>
  );
};

export default Product;
