import React, { useState } from "react";
import { contactAddEmail } from "../../api/contactReducer";
import { useDispatch, useSelector } from "react-redux";
import Loading from "../Loading";
import { t } from "i18next";
const ContactLeftInputs = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [mess, setMess] = useState("");
  const [error, setError] = useState(false);

  const dispatch = useDispatch();
  const data = {
    name,
    email,
    subject: mess,
  };
  const SendMessage = () => {
    if (!name || !email || !mess) {
      setError(true);
    } else {
      dispatch(contactAddEmail(data));
    }
    //console.log(data);
  };
  const loading = useSelector((state) => state?.ContactSlice?.sendLoading);
  const status = useSelector((state) => state?.ContactSlice?.mail?.Status);
  // console.log(loading);
  return (
    <div className="lg:w-[40vw] w-full h-[70vh] shadow-productBarShadow rounded-3xl flex items-center justify-center flex-col gap-8">
      <p className="text-xl text-[#252B42] font-bold ">{t("Contact Us")}</p>
      <input
        type="text"
        name="name"
        onChange={(e) => setName(e.target.value)}
        placeholder={t("Your Name")}
        className={`w-[80%] bg-[#E8E8E8] pl-5 pr-5 rounded-full border ${
          error ? "border-red-700" : "border-[#DDDDDD]"
        }  text-[#777777] font-medium text-sm h-[10%] focus:outline-none `}
      />
      <input
        type="text"
        name="email"
        onChange={(e) => setEmail(e.target.value)}
        placeholder={t("You Email")}
        className={`w-[80%] bg-[#E8E8E8] pl-5 pr-5 rounded-full border  text-[#777777] ${
          error ? "border-red-700" : "border-[#DDDDDD]"
        } font-medium text-sm h-[10%] focus:outline-none`}
      />
      <textarea
        name="massage"
        onChange={(e) => setMess(e.target.value)}
        style={{ resize: "none" }}
        className={` p-5 bg-[#E8E8E8] w-[80%] rounded ${
          error ? "border-red-700" : "border-[#DDDDDD]"
        } text-[#777777] font-medium border text-sm h-[30%] focus:outline-none`}
        placeholder={t("Your Massage")}
      />
      <div className="self-start flex items-center gap-9">
        <button
          className="bg-newOrange px-8 py-4 text-white font-medium rounded-2xl  ml-14"
          onClick={() => SendMessage()}
        >
          {loading ? (
            <Loading
              type={"spinningBubbles"}
              color={"#Ffffff"}
              height={15}
              width={15}
            />
          ) : (
            "Send"
          )}
        </button>
        {status && status === 200 ? (
          <p className="text-base font-semibold text-green-600">
            Email Sent Successfully
          </p>
        ) : (
          status && (
            <p className="text-base font-semibold text-red-700">Faild</p>
          )
        )}
      </div>
    </div>
  );
};

export default ContactLeftInputs;
