import React from "react";
import BlurBg from "../assets/blurbg.png";
import Logo from "../assets/nardi-logo.png";
import Italia from "../assets/italia.png";
const BlueBg = () => {
  return (
    <div className="bg-white w-[98vw] h-[50vh] lg:h-[67vh] absolute top-0 lg:flex flex-col items-center justify-center hidden ">
      <img
        src={BlurBg}
        alt={BlurBg}
        className="absolute top-0 w-[96vw] h-full left-8 blur z-30 object-contain"
      />
      <img
        src={Logo}
        alt={Logo}
        className="z-50 w-[35vw] object-contain mt-10"
      />
      <img
        src={Italia}
        alt={Italia}
        className="z-50 w-[20vw] object-contain mt-10"
      />
    </div>
  );
};

export default BlueBg;
