import React, { useState } from "react";
import { FaBars } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import Select from "react-select";

import Logo from "../assets/nardi-logo.png";
import CustomNavMobile from "./CustomNavMobile";
import { getLang } from "../api/LangReducer";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
const NavBarMobile = () => {
  const { t, i18n } = useTranslation();
  // const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showNav, setShowNav] = useState(false);
  // const showLogo = location.pathname === "/home";
  const langs = useSelector((state) => state?.LangReducer?.lang?.langs || []);
  const optionslang = langs?.map((item) => ({
    value: item.code,
    label: t(item.code.toUpperCase()), // Convert label to uppercase
    label2: item.name, // Convert label to uppercase
  }));
  const StorageLang = localStorage.getItem("lang");
  const [selectedOption, setSelectedOption] = useState("");
  useEffect(() => {
    dispatch(getLang());
  },[] );
  useEffect(() => {
    const newLang = optionslang?.find((item) => item.value === StorageLang);
    setSelectedOption(newLang);
  }, [langs, StorageLang]);
  const customStyles = {
    control: (base, state) => ({
      ...base,
      borderColor: state.isFocused ? "none" : "none",
      backgroundColor: "#EA5C24",
      color: "#fff",
    }),
    dropdownIndicator: () => ({ display: "none" }), // Hide the dropdown indicator
    indicatorSeparator: () => ({ display: "none" }), // Hide the separator

    option: (base, state) => ({
      ...base,
      borderColor: state.isFocused ? "none" : "none",

      backgroundColor: state.isFocused ? "#EA5C24" : "#fff", // Change the background color when focused
      color: state.isFocused ? "#fff" : "#000", // Change the text color when focused
    }),
    singleValue: (base) => ({
      ...base,
      borderColor: "none",

      color: "#fff", // Change the text color for the selected option
    }),
  };
  const isRTL = document.documentElement.dir === "rtl";
  const additionalStyles = isRTL ? {} : {};
  const mergedStyles = { ...customStyles, ...additionalStyles };

  const handleChange = (selectedOption) => {
    // Reload the page first
    window.location.reload();

    // Set the selected language and update body direction (this will happen after the reload)
    setSelectedOption(selectedOption);
    const selectedLanguage = selectedOption.label2.toLowerCase();
    console.log("first", selectedLanguage);
    if (selectedLanguage === "Arabic") {
      document.body.dir = "rtl";
    } else {
      document.body.dir = "ltr";
    }
    localStorage.setItem("lang", selectedOption.value);
    i18n.changeLanguage(selectedLanguage);
  };
  return (
    <>
      <div className="flex items-center justify-between w-[110vw] px-4 py-2 z-50 bg-white lg:px-8 shadow-xl">
        <button onClick={() => setShowNav(!showNav)}>
          <FaBars className="text-newOrange text-2xl" />
        </button>
        <img
          src={Logo}
          alt="Nardi-Logo"
          className="w-40
          h-16 object-contain"
          onClick={() => navigate("/")}
        />
        {/* <button className="flex items-center justify-center bg-newOrange text-lg px-2 py-1 rounded-md text-white">
          En <IoMdArrowDropdown className="text-lg" />
        </button> */}
        <div className="w-[11vw] items-center justify-center">
          <Select
            styles={mergedStyles}
            defaultValue={selectedOption}
            value={selectedOption}
            onChange={handleChange}
            options={optionslang}
            isSearchable={false}
          />
        </div>
      </div>
      <div hidden={showNav !== true}>
        <CustomNavMobile setShowNav={setShowNav} showNav={showNav} />
      </div>
    </>
  );
};

export default NavBarMobile;
