import React from "react";

const ContactIconItem = ({ data, icon }) => {
  return (
    <div className="flex w-full items-center justify-center gap-5 flex-col">
      <div>{icon}</div>
      <p className="font-medium text-sm text-[#818181] w-full text-center">
        {data}
      </p>
    </div>
  );
};

export default ContactIconItem;
 