import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { newBaseUrl } from "../Consts";

export const getContactsLocations = createAsyncThunk(
  `Contacts/GetLocations`,
  async (_, thunkApi) => {
    const lang = localStorage.getItem("lang");
    const locationCode = localStorage.getItem("location");
    try {
      const res = await axios.get(
        `${newBaseUrl}${lang}/api/Site/ContectUs/${locationCode}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      return res.data;
    } catch (error) {
      console.log(error);
    }
  }
);
export const contactAddEmail = createAsyncThunk(
  `Contacts/sendEmail`,
  async (data, thunkApi) => {
    try {
      const res = await axios.post(`${newBaseUrl}Site/ContectUs/store`, data, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      return res.data;
    } catch (error) {
      console.log(error);
    }
  }
);

const ContactSlice = createSlice({
  name: "Contact",
  initialState: {
    contact: [],
    loading: false,
    error: null,
    sendLoading: false,
    mail: [],
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getContactsLocations.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.contact = [];
      })
      .addCase(getContactsLocations.fulfilled, (state, action) => {
        state.contact = action.payload;
        state.loading = false;
        state.error = null;
      })
      .addCase(getContactsLocations.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error;
      })
      .addCase(contactAddEmail.pending, (state) => {
        state.sendLoading = true;
        state.error = null;
      })
      .addCase(contactAddEmail.fulfilled, (state, action) => {
        state.mail = action.payload;
        state.sendLoading = false;
        state.error = null;
      })
      .addCase(contactAddEmail.rejected, (state, action) => {
        state.sendLoading = false;
        state.error = action.error;
      });
  },
});

export default ContactSlice.reducer;
