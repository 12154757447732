import React from "react";

const ProductDes = ({ product }) => {
  return (
    <div className="flex flex-col lg:gap-6 gap-3 items-center justify-center lg:items-start lg:justify-start w-[100vw] lg:w-[20vw]">
      <p className="lg:text-lg text-base text-newOrange font-semibold">
        {product?.name}
      </p>
      <p className="text-[#777777] font-light text-sm text-center lg:text-left  lg:text-lg">
        {product?.description}
      </p>
    </div>
  );
};

export default ProductDes;
