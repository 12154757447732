import React, { useState } from "react";
import ProductBodyBlockItem from "./ProductBodyBlockItem";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "./style.css";
import { Autoplay } from "swiper";

const ProducBodyBlock = ({ data }) => {
  const [all, setAll] = useState(false);
  return (
    <div className="flex items-start w-[90vw] lg:w-[75vw] flex-col pt-6 gap-6">
      {/* Block Header */}

      <div className="flex w-full items-center justify-between">
        <p className="text-newOrange font-medium text-2xl">{data?.name}</p>
        {data?.products?.length > 4 && (
          <button
            onClick={() => setAll(!all)}
            className="text-newOrange font-normal text-base"
          >
            See All
          </button>
        )}
      </div>
      {/* Block Image */}
      {/*  */}
      {/* 
        ))} */}
      {all ? (
        <div className="flex  flex-wrap items-center justify-center h-[85vh] overflow-auto gap-4">
          {data?.products?.map((product, i) => (
            <div key={i} className="pt-2">
              <ProductBodyBlockItem product={product} />
            </div>
          ))}
        </div>
      ) : (
        <Swiper
          slidesPerView={1}
          spaceBetween={10}
          autoplay={{
            delay: 2500,
            disableOnInteraction: false,
          }}
          breakpoints={{
            640: {
              slidesPerView: 2,
              spaceBetween: 20,
            },
            768: {
              slidesPerView: 4,
              spaceBetween: 20,
            },
            1024: {
              slidesPerView: 4,
              spaceBetween: 20,
            },
          }}
          modules={[Autoplay]}
          className="mySwiper"
        >
          {data?.products?.map((product, i) => (
            <SwiperSlide key={i} className="swiper2222">
              <ProductBodyBlockItem product={product} />
            </SwiperSlide>
          ))}
        </Swiper>
      )}
      {/* </div> */}
    </div>
  );
};

export default ProducBodyBlock;
