import React, { useCallback, useEffect } from "react";
import Logo1 from "../assets/logo1.png";
import Logo2 from "../assets/logo2.png";
import Logo3 from "../assets/logo3.png";
import "./intro.css";
import { getLang } from "../api/LangReducer";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import useGeoLocation from "react-ipgeolocation";
import { getContactsLocations } from "../api/contactReducer";
import { getCategory } from "../api/categoryReducer";
import { Helmet } from "react-helmet";

const Intro = () => {
  const dispatch = useDispatch();
  const fetchCetegory = useCallback(() => {
    dispatch(getCategory());
  }, [dispatch]);
  const fetchData = useCallback(() => {
    dispatch(getContactsLocations());
  }, [dispatch]);
  useEffect(() => {
    dispatch(getLang());
    fetchData();
    fetchCetegory();
  }, [fetchData, fetchCetegory]);
  const location = useGeoLocation();

  const StorageLang = localStorage.getItem("lang");
  // console.log(location, "====");
  useEffect(() => {
    if (location.country !== undefined) {
      // Check if location.country is defined
      localStorage.setItem("location", location.country);
    } else {
      localStorage.setItem("location", "EG");
    }
    if (!StorageLang) {
      localStorage.setItem("lang", "fr");
    } else {
      if (StorageLang === "ar") {
        document.body.dir = "rtl";
      } else {
        document.body.dir = "ltr";
      }
    }
  }, [location.country, StorageLang]); // Include location.country and StorageLang in the dependency array

  return (
    <div className="flex items-center justify-center w-screen h-screen flex-col">
      <Helmet>
        <title>Nardi Website </title>
        <meta name="description" content="Stove" />
        <meta property="og:title" content="Nardi nardi nardi nardi " />
        <meta property="og:description" content="stove stove stove." />
      </Helmet>
      <div className="flex items-center justify-center  flex-row container">
        <img
          src={Logo1}
          alt={Logo1}
          className="w-24 h-20 object-contain first"
        />
        <img
          src={Logo2}
          alt={Logo2}
          className="w-56 h-24 object-contain second"
        />
      </div>
      <img src={Logo3} alt={Logo3} className="w-80 h-8 object-contain third" />
    </div>
  );
};

export default Intro;
