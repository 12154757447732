import React, { useState } from "react";
import { MdArrowRight } from "react-icons/md";
import { geyProductByCategory } from "../../api/ProductsByCategory";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
const LeftBarItem = ({ data }) => {
  const navigate = useNavigate();
  const [showMain, setShowMain] = useState(false);
  const [selectedSubIndex, setSelectedSubIndex] = useState(null);
  const [selectedCatIndex, setSelectedCatIndex] = useState(null);
  const dispatch = useDispatch();
  const handleMainClick = () => {
    setShowMain(!showMain);
    setSelectedSubIndex(null); // Reset the selected sub-index when main category is clicked
  };

  const handleSubClick = (index) => {
    if (selectedSubIndex === index) {
      setSelectedSubIndex(null); // Close the sub-category if it's already open
    } else {
      setSelectedSubIndex(index); // Open the clicked sub-category
    }
    setSelectedCatIndex(null); // Reset the selected category index when a new sub-category is clicked
  };

  const handleCatClick = (index, id) => {
    setSelectedCatIndex(index); // Set the selected category index
    dispatch(geyProductByCategory(id));
    navigate(`/productsByCategory/${id}`);
  };
  // const handlefetchData = (id) => {
  //   dispatch(geyProductByCategory(id));
  // };
  return (
    <div className="w-full h-full overflow-auto">
      <button
        onClick={handleMainClick}
        className="bg-[#B4B4B4] flex items-center gap-2 w-full"
      >
        <MdArrowRight className="text-[#D9D9D9] text-4xl" />
        <p className="text-white font-medium text-xl">{data.name}</p>
      </button>
      <div hidden={!showMain}>
        {data?.children?.map((item, i) => (
          <div key={i} className="">
            <button
              onClick={() => handleSubClick(i)}
              className="flex gap-2 items-center ml-4"
            >
              <MdArrowRight className="text-[#D9D9D9] text-4xl" />
              <p className="text-black font-medium text-lg">{item.name}</p>
            </button>
            <div hidden={selectedSubIndex !== i}>
              {item?.children?.map((subItem, j) => (
                <button
                  onClick={() => handleCatClick(j, subItem.id)}
                  key={j}
                  className="flex gap-2 ml-12"
                >
                  <div
                    className={`w-6 h-6 ${
                      selectedCatIndex === j ? "bg-newOrange" : "bg-[#D9D9D9]"
                    } `}
                  ></div>
                  <p
                    className={`${
                      selectedCatIndex === j
                        ? "text-newOrange"
                        : "text-[#777777]"
                    } text-lg font-normal`}
                  >
                    {subItem.name}
                  </p>
                </button>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default LeftBarItem;
