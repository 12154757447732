import React, { useState } from "react";
import { RiArrowDownSLine } from "react-icons/ri";
import { RiContactsBook2Line } from "react-icons/ri";
import { FiPhoneCall } from "react-icons/fi";
import { MdOutlineAlternateEmail } from "react-icons/md";
import { t } from "i18next";
// import { AiOutlineCalendar } from "react-icons/ai";
const ContactCard = ({ itemData }) => {
  //  console.log(itemData);
  const [show, setShow] = useState(false);
  return (
    <div className="flex w-[90vw] flex-col items-center">
      <button
        className="bg-newOrange w-full lg:w-[20vw] md:h-[5vh] h-[9vh] lg:h-[9vh] rounded-t-lg flex items-center justify-between px-4"
        onClick={() => setShow(!show)}
      >
        <p className="text-white text-lg font-medium ">{itemData?.location}</p>
        <RiArrowDownSLine className="text-white text-xl " />
      </button>
      <div
        className="bg-white w-[90vw] lg:w-[20vw] gap-4 p-4"
        hidden={show !== true}
      >
        <p className="text-black text-lg font-semibold pb-3">{t("Contact Info")}</p>
        <div className="flex flex-col gap-2">
          {/* sssssss */}
          <div className="flex items-center justify-start gap-3">
            <RiContactsBook2Line className="text-black text-[20px] md:text-lg lg:text-2xl" />
            <p className="flex items-center text-xs font-medium gap-2">
              {itemData?.address}
            </p>
          </div>
          <div className="flex items-center justify-start gap-3">
            <MdOutlineAlternateEmail className="text-black text-[20px] md:text-lg lg:text-2xl" />
            <p className="flex items-center text-xs font-medium gap-2">
              {itemData?.email}
            </p>
          </div>
          <div className="flex items-center justify-start gap-3">
            <FiPhoneCall className="text-black text-[20px] md:text-lg lg:text-2xl" />
            <p className="flex items-center text-xs font-medium gap-2">
              {itemData?.phone}
            </p>
          </div>
          <div className="flex items-center justify-start gap-3">
            {/* <RiContactsBook2Line className="text-black text-[20px] md:text-lg lg:text-2xl" /> */}
            <p className="flex items-center text-xs font-medium gap-2">
              {t("Sunday - Thursday / 8:00 - 17:00")}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactCard;
