import React from "react";

import ProductBodyBlockItem from "../ProductPageComponent/ProductBodyBlockItem";
import { Helmet } from "react-helmet";

const ProducBodyBlockCat = ({ data }) => {
  return (
    <div className="flex items-start w-[90vw] lg:w-[75vw] flex-col pt-6 gap-6">
      {/* <Helmet>
        <meta name="description" content={data?.products.seo} />
        <meta property="og:title" content={data?.products.name} />
        <meta property="og:description" content={data?.products.description} />
      </Helmet> */}
      {/* Block Header */}
      <div className="flex w-full items-center justify-between">
        <p className="text-newOrange font-medium text-2xl">{data?.name}</p>
        {/* {data.children.length > 4 && (
          <button
            onClick={() => setAll(!all)}
            className="text-newOrange font-normal text-base"
          >
            See All
          </button>
        )} */}
      </div>

      <div className="flex  flex-wrap items-center   gap-4">
        {data?.products?.map((product, i) => (
          <div key={i} className="pt-2">
            <ProductBodyBlockItem product={product} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default ProducBodyBlockCat;
