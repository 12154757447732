import React, { useCallback, useEffect, useState } from "react";
import BlueBg from "../Components/BlueBg";
import ProductLeftFilterBar from "../Components/ProductPageComponent/ProductLeftFilterBar";
import ProductBody from "../Components/ProductPageComponent/ProductBody";
import { useParams } from "react-router-dom";
import { geyProductByCategory } from "../api/ProductsByCategory";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
const Products = () => {
  const [show, setShow] = useState(false);
  const dispatch = useDispatch();
  const id = useParams().id;
  const fetchData = useCallback(() => {
    dispatch(geyProductByCategory(id));
  }, [dispatch, id]);
  useEffect(() => {
    fetchData();
  }, [fetchData]);
  const categoryData = useSelector(
    (state) => state?.CategorySlice?.category?.resulte || []
  );

  const productsData = useSelector(
    (state) => state?.ProductsCatSlice?.productsByCategory || []
  );
  const loadingProduct = useSelector(
    (state) => state?.ProductsCatSlice?.loading
  );
  const loadingCategory = useSelector((state) => state?.CategorySlice?.loading);
  return (
    <div className="bg-white overflow-hidden">
      <Helmet>
        <title>Products</title>
        <meta name="description" content="hhhhhhi" />
        <meta property="og:title" content="hhhhhhi" />
        <meta property="og:description" content="hhhhhhi" />
      </Helmet>
      <BlueBg />
      <div className=" w-full flex pl-4  lg:pt-[28rem] pb-[1rem] overflow-auto  lg:gap-7">
        <ProductLeftFilterBar
          setShow={setShow}
          show={show}
          loadingCategory={loadingCategory}
          categoryData={categoryData}
        />
        <ProductBody
          setShow={setShow}
          show={show}
          loadingProduct={loadingProduct}
          productsData={productsData}
        />
      </div>
    </div>
  );
};

export default Products;
