import React from "react";
import ProductCircle from "./ProductCircle";

const ProductByIdLeft = ({ product }) => {
  return (
    <div className=" flex flex-col items-center justify-center  gap-3 w-full lg:w-[10vw]">
      {/* <p className="text-lg  text-newOrange font-semibold">Dimension (cm)</p> */}
      <div className="flex lg:flex-col flex-row gap-4">
        {product?.specifications?.map((item, i) => (
          <ProductCircle key={i} item={item} />
        ))}
      </div>
    </div>
  );
};

export default ProductByIdLeft;
