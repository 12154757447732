import React, { useState } from "react";
import ProductDes from "./ProductDes";

import ProductPopUp from "./ProductPopUp";
import { newBaseUrlImg } from "../../Consts";

const ProductImages = ({ images, product }) => {
  const [currentImage, setCurrentImage] = useState(
    images?.length > 0 && images[0]?.image
  );
  const [show, setShow] = useState(false);
  const handleImageClick = (image) => {
    setCurrentImage(image);
  };
  //console.log(images);
  return (
    <>
      <div className="flex flex-col w-full lg:w-[50vw] gap-4">
        <div className="flex lg:flex-row flex-col-reverse items-center gap-4 w-full">
          <button
            className="px-8 py-3 w-[80vw] md:w-[40vw] lg:w-[24vw]  shadow-productBarShadow"
            onClick={() => setShow(!show)}
          >
            <img
              src={`${newBaseUrlImg}${currentImage}`}
              alt={currentImage}
              className="lg:w-[20vw] lg:h-[40vh] object-contain w-[75vw] h-[35vh] md:h-[35vh] md:w-[40vw]"
            />
          </button>
          <ProductDes product={product} />
        </div>
        <div className="lg:w-[60vw] w-[100vw] flex flex-wrap flex-row gap-5 items-center justify-center lg:items-start lg:justify-start">
          {images?.map((item, i) => (
            <button
              className="p-3 bg-[#D9D9D9]"
              onClick={() => handleImageClick(item?.image)}
              key={i}
            >
              <img
                src={`${newBaseUrlImg}${item?.image}`}
                alt={item?.image}
                className="lg:w-[6rem] lg:h-[6rem] w-20 h-20 object-contain"
              />
            </button>
          ))}
        </div>
      </div>
      <div hidden={show !== true}>
        <ProductPopUp
          show={show}
          setShow={setShow}
          currentImage={currentImage}
        />
      </div>
    </>
  );
};

export default ProductImages;
