import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { newBaseUrl } from "../Consts";
export const getLang = createAsyncThunk(
  `lang/getLanguages`,
  async (_, thunkApi) => {
    try {
      const res = await axios.get(`${newBaseUrl}api/Site/lang`, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      return res.data;
    } catch (error) {
      console.log(error);
    }
  }
);
const LangSlice = createSlice({
  name: "lang",
  initialState: { lang: [], loading: false, error: null },
  extraReducers: {
    [getLang.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [getLang.fulfilled]: (state, action) => {
      state.lang = action.payload;
      state.loading = false;
      state.error = null;
    },
    [getLang.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.action;
    },
  },
});

export default LangSlice.reducer;
