import React from "react";
import ContactIcons from "./ContactIcons";
import ContactLeftInputs from "./ContactLeftInputs";

const ContactLeft = ({ data }) => {
  return (
    <div className="flex flex-col w-full pt-6 lg:pt-0 lg:w-[50vw]  items-center justify-center gap-8">
      <ContactIcons data={data} />
      <ContactLeftInputs />
    </div>
  );
};

export default ContactLeft;
