import React from "react";
import { IoIosArrowForward } from "react-icons/io";
import Banner1 from "../assets/banner1.png";
const HomerBanner = ({ data }) => {
  return (
    <div className="flex flex-col lg:flex-row items-center justify-between lg:gap-5 ">
      <div className="flex items-start flex-col">
        <p className="lg:text-7xl text-4xl md:text-6xl font-extralight text-[#797979]">
          {data.name}
        </p>
        <span className="text-newOrange text-xs md:text-sm lg:text-base font-medium">
          {/* {data.title} */}
        </span>
        <div className="flex items-start ml-3  gap-2 lg:ml-32 mt-8">
          <IoIosArrowForward className="lg:text-3xl md:text-2xl text-base text-[#797979]" />
          <p className="lg:text-xl md:text-base text-sm font-light w-full lg:w-[80%]">
            {data.desc}
          </p>
        </div>
      </div>
      <img
        src={data.img}
        alt="banner"
        loading="lazy"
        className="w-[33rem] h-full  lg:h-[22rem] object-contain mt-8 "
      />
    </div>
  );
};

export default HomerBanner;
