import React, { useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
import { NavLink, useNavigate } from "react-router-dom";

import {
  IoMdArrowDropdown,
  IoMdArrowDropright,
  IoIosArrowBack,
} from "react-icons/io";
import { useSelector } from "react-redux";
import { newBaseUrlImg } from "../Consts";
import { useTranslation } from "react-i18next";

const CustomNavMobile = ({ showNav, setShowNav }) => {
  const navigate = useNavigate();
  const categoryData = useSelector(
    (state) => state?.CategorySlice?.category?.resulte || []
  );
  const { t } = useTranslation();

  const [showCat, setShowCat] = useState(false);
  const [catItem, setCatItem] = useState(false);
  const [hoverItem, setHoverItem] = useState(false);
  // console.log(showCat);

  const [expandedItems, setExpandedItems] = useState([]);
  const [expandedItems2, setExpandedItems2] = useState([]);
  const toggleItem = (index) => {
    if (expandedItems.includes(index)) {
      setExpandedItems(expandedItems.filter((item) => item !== index));
    } else {
      setExpandedItems([...expandedItems, index]);
    }
  };
  const forward = (item) => {
    setShowNav(!showNav);
    navigate(`/products/${item?.id}`);
  };
  const toggleItem2 = (index, item) => {
    setHoverItem(item);
    if (expandedItems2.includes(index)) {
      setExpandedItems2(expandedItems2.filter((item) => item !== index));
    } else {
      setExpandedItems2([...expandedItems2, index]);
    }
  };
  // console.log(categoryData, "====");
  return (
    <div className="fixed  left-0 top-[95px] z-10 bg-newOrange w-[80vw] md:w-[40vw] rounded-tr-3xl h-[70vh] rounded-br-3xl">
      <div className="flex relative items-center justify-between px-6 py-5 ">
        <p className="text-white text-2xl font-normal">Menu</p>
        <button onClick={() => setShowNav(!showNav)}>
          <AiOutlineClose className="text-white text-2xl" />
        </button>
      </div>
      {/* Start Nav */}
      <div className="w-[80%] bg-white h-[85%] rounded-tr-3xl rounded-br-3xl flex flex-col items-start justify-center px-8 gap-6">
        <NavLink
          className={({ isActive }) =>
            isActive
              ? "text-newOrange  text-base font-medium border-b border-newOrange pb-3 w-[80%]"
              : "text-base font-medium border-b border-newOrange w-[80%] pb-3"
          }
          to="/"
        >
          {t("Home")}
        </NavLink>
        <button
          onClick={() => setShowCat(!showCat)}
          className={`border-b border-newOrange pb-3 w-[80%] bg-white text-left text-base font-medium `}
        >
          {t("Products")}
        </button>
        <NavLink
          className={({ isActive }) =>
            isActive
              ? "text-newOrange  text-base font-medium border-b border-newOrange pb-3 w-[80%]"
              : "text-base font-medium border-b border-newOrange w-[80%] pb-3"
          }
          to="/news"
        >
          {t("News")}
        </NavLink>
        <NavLink
          className={({ isActive }) =>
            isActive
              ? "text-newOrange  text-base font-medium border-b border-newOrange pb-3 w-[80%]"
              : "text-base font-medium border-b border-newOrange w-[80%] pb-3"
          }
          to="/about"
        >
          {t("About")}
        </NavLink>
        <NavLink
          className={({ isActive }) =>
            isActive
              ? "text-newOrange  text-base font-medium border-b border-newOrange pb-3 w-[80%]"
              : "text-base font-medium border-b border-newOrange w-[80%] pb-3"
          }
          to="/contact"
        >
          {t("Contact")}
        </NavLink>
      </div>
      {/* Start Category */}
      <div
        hidden={showCat !== true}
        className="bg-white absolute rounded-tr-3xl rounded-br-3xl top-[0px] shadow-2xl  w-[101%]  h-[100%]"
      >
        <div className="bg-newOrange w-full rounded-tr-3xl  py-2 px-2 gap-2 flex flex-col">
          {/* Hereeeeee */}
          <div className="flex items-center justify-between">
            <p className="text-white text-base">
              {hoverItem ? hoverItem?.name : categoryData[0]?.name}
            </p>
            <button onClick={() => setShowCat(!showCat)}>
              <IoIosArrowBack className="text-xl text-white" />
            </button>
          </div>
          <span className="text-xs font-normal">{hoverItem?.desc}</span>
        </div>
        <div className="overflow-auto h-[52vh] pb-10">
          {categoryData?.map((item, i) => (
            <div key={i}>
              {item?.children.length > 0 && (
                <div className="flex flex-row items-center justify-start gap-6 p-3">
                  <button onClick={() => toggleItem2(i, item)}>
                    {expandedItems2.includes(i) ? (
                      <IoMdArrowDropdown className="text-[#787878] text-2xl" />
                    ) : (
                      <IoMdArrowDropright className="text-[#787878] text-2xl" />
                    )}
                  </button>
                  <button
                    onClick={() => forward(item)}
                    className="flex flex-row items-center justify-start gap-4"
                  >
                    <img
                      src={`${newBaseUrlImg}${item.image}`}
                      alt={item.img}
                      className="w-10 h-10 object-contain"
                    />
                    <p
                      className={`${
                        expandedItems2.includes(i)
                          ? "text-newOrange"
                          : "text-black"
                      } font-medium text-base`}
                    >
                      {item.name}
                    </p>
                  </button>
                </div>
              )}
              <div hidden={!expandedItems2.includes(i)}>
                {item?.children?.map((cat, i) => (
                  <div key={i}>
                    <div className="flex items-center px-8 py-3">
                      <button
                        className={`font-medium text-md flex  ${
                          expandedItems.includes(i) ? "text-newOrange" : ""
                        }`}
                        onClick={() => {
                          toggleItem(i);
                          setCatItem(!catItem);
                        }}
                      >
                        {expandedItems.includes(i) ? (
                          <IoMdArrowDropdown className="text-[#787878] text-2xl" />
                        ) : (
                          <IoMdArrowDropright className="text-[#787878] text-2xl" />
                        )}
                        {cat.name}
                      </button>
                    </div>
                    <div hidden={!expandedItems.includes(i)}>
                      {cat?.children?.map((item, i) => (
                        <div key={i} className="px-14 py-2">
                          <p
                            className="text-[#8C8C8C] font-normal cursor-pointer hover:text-newOrange "
                            onClick={() => {
                              navigate(`productsByCategory/${item.id}`);
                              setShowNav(!showNav);
                            }}
                          >
                            {item.name}
                          </p>
                        </div>
                      ))}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default CustomNavMobile;
