import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import Home from "./Pages/Home";
import Products from "./Pages/Products";
import News from "./Pages/News";
import About from "./Pages/Aboutus";
import Contact from "./Pages/Contact";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import Layout from "./Pages/Layout";
import Product from "./Pages/Product";
import store from "./api/store";
import { Provider } from "react-redux";
import ProductsByCategoryPage from "./Pages/ProductsByCategoryPage";
import Intro from "./Components/Intro";
import "./i18next";
import { HelmetProvider } from "react-helmet-async";
const router = createBrowserRouter([
  {
    path: "/",
    element: <Layout />,
    children: [
      {
        path: "/",
        index: true,
        element: <Home />,
      },
      {
        path: "/products/:id",
        element: <Products />,
      },
      {
        path: "/productsByCategory/:id",
        element: <ProductsByCategoryPage />,
      },
      {
        path: "/product/:id",
        element: <Product />,
      },
      {
        path: "/news",
        element: <News />,
      },
      {
        path: "/about",
        element: <About />,
      },
      {
        path: "/contact",
        element: <Contact />,
      },
    ],
  },
]);

const root = ReactDOM.createRoot(document.getElementById("root"));

const showIntroAndRedirect = () => {
  root.render(
    <React.StrictMode>
      <Provider store={store}>
        {/* <HelmetProvider> */}
        <Intro />
        {/* </HelmetProvider> */}
      </Provider>
    </React.StrictMode>
  );

  setTimeout(() => {
    root.render(
      <React.StrictMode>
        <Provider store={store}>
          <RouterProvider router={router} />
        </Provider>
      </React.StrictMode>
    );
  }, 5000); // Delay of 4 seconds (4000 milliseconds)
};

showIntroAndRedirect();
