import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { newBaseUrl } from "../Consts";
export const getCategory = createAsyncThunk(
  `category/getCategory`,
  async (_, thunkApi) => {
    const lang = localStorage.getItem("lang");
    try {
      const res = await axios.get(`${newBaseUrl}${lang}/api/Site/categories`, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      return res.data;
    } catch (error) {
      console.log(error);
    }
  }
);
const CategorySlice = createSlice({
  name: "Category",
  initialState: { category: [], loading: false, error: null },
  extraReducers: {
    [getCategory.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [getCategory.fulfilled]: (state, action) => {
      state.category = action.payload;
      state.loading = false;
      state.error = null;
    },
    [getCategory.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.action;
    },
  },
});

export default CategorySlice.reducer;
