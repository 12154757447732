import React from "react";
import Loading from "../../Components/Loading";
import LeftBarItem from "./LeftBarItem";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { Helmet } from "react-helmet";
import { t } from "i18next";
const ProductLeftFilterBar = ({
  show,
  setShow,
  categoryData,
  loadingCategory,
}) => {
  // console.log(show, "");

  return (
    <>
      <Helmet>
        <meta name="description" content={categoryData?.seo} />
        <meta property="og:title" content={categoryData?.name} />
        <meta property="og:description" content={categoryData?.description} />
      </Helmet>
      <div
        className={`shadow-productBarShadow    lg:w-[20vw] lg:h-[100vh] w-[100vw] h-[100vh] bg-white lg:rounded-tr-[52px] lg:rounded-br-[52px]  ${
          show ? "fixed flex justify-between" : "lg:flex hidden "
        }  z-50 lg:relative top-0 left-0  lg:gap-4 gap-8 flex-col pt-10`}
      >
        <div className="flex flex-col gap-5 relative">
          <div className="flex items-center justify-center w-full">
            <p className="text-lg font-medium text-newOrange">{t("Categories")}</p>
          </div>
          <button
            className="absolute right-5 lg:hidden"
            onClick={() => setShow(!show)}
          >
            <AiOutlineCloseCircle fontSize={20} className="text-black" />
          </button>
          {loadingCategory ? (
            <div className="w-full h-[50vh] flex items-center justify-center">
              <Loading
                type="spinningBubbles"
                color="#EA5C24"
                height={30}
                width={30}
              />
            </div>
          ) : (
            <div className={`overflow-auto h-[80vh] flex flex-col gap-4`}>
              {categoryData.map((item, i) => (
                <div key={i} className="">
                  <LeftBarItem data={item} />
                </div>
              ))}
            </div>
          )}
        </div>
        <button
          className="bg-newOrange lg:hidden p-3 w-32 text-white text-base font-medium rounded-xl self-center mb-7"
          onClick={() => setShow(!show)}
        >
          Done
        </button>
      </div>
    </>
  );
};

export default ProductLeftFilterBar;
