import React from "react";
import ProductDataWrapper from "./ProductDataWrapper";
import ProductDataWrapperFiles from "./ProductDataWrapperFiles";

const ProductData = ({ product }) => {
  console.log(product);
  return (
    <div className=" flex flex-wrap justify-center items-start  lg:grid-cols-2 grid-cols-1">
      {product?.info?.map((item, i) => (
        <ProductDataWrapper item={item} key={i} />
      ))}
      {/* <ProductDataWrapperFiles item={product?.SupportFiles} /> */}
    </div>
  );
};

export default ProductData;
