import React from "react";
import HomerBanner from "../Components/HomerBanner";
import HomeSlider from "../Components/HomeSlider";
import ContentContainer from "../Components/ContentContainer";
import Banner1 from "../assets/banner3.jpg";
import Banner2 from "../assets/banner4.jpg";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
const Home = () => {
  const { t } = useTranslation();
  const data1 = {
    name: t("Nardi Kitchens"),
    title: t("Enlever Freestanding"),
    desc: t(
      "Nardi Italian kitchen cuisine is known all over the world for its rich flavors, fresh ingredients, and dedication to tradition. The Italian kitchen is a place where family, friends, and food come together to create a warm and welcoming atmosphere."
    ),
    img: Banner1,
  };
  const data2 = {
    name: t("Nardi Ovens"),
    title: t("BUILT-IN model"),
    desc: t(
      "Nardi Italian kitchen cuisine is known all over the world for its rich flavors, fresh ingredients, and dedication to tradition. The Italian kitchen is a place where family, friends, and food come together to create a warm and welcoming atmosphere."
    ),
    img: Banner2,
  };
  return (
    <div className="p-6 md:p-12 flex flex-col gap-12">
      <Helmet>
        <title>Home</title>
        <meta name="description" content="Home" />
        <meta property="og:title" content="Home nardi Home nardi " />
        <meta property="og:description" content="stove Home stove." />
      </Helmet>
      <HomeSlider />
      <HomerBanner data={data1} />
      <ContentContainer />
      <HomerBanner data={data2} />
    </div>
  );
};

export default Home;
