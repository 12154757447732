import React from "react";
import { AiFillCloseCircle } from "react-icons/ai";
import { newBaseUrlImg } from "../../Consts";
const ProductPopUp = ({ currentImage, show, setShow }) => {
  return (
    <div className="fixed top-0  lg:top-4 left-0 lg:left-40 z-50 flex items-center justify-center bg-transparent">
      <div className="absolute w-[101vw] h-[101vh]  -ml-4 bg-black opacity-40 z-10" />
      <div className="lg:w-[80vw] w-[100vw] h-[100vh]   lg:h-[95vh] z-20 bg-white flex items-center justify-center flex-col rounded-lg">
        <button onClick={() => setShow(!show)} className="self-end">
          <AiFillCloseCircle className="text-4xl text-black self-end pr-3" />
        </button>
        <img
          src={`${newBaseUrlImg}${currentImage}`}
          alt={currentImage}
          className="w-[80vw] h-[80vh] object-contain"
        />
      </div>
    </div>
  );
};

export default ProductPopUp;
