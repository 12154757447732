import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { newBaseUrl } from "../Consts";

export const getNews = createAsyncThunk(
  `News/getNews`,
  async (date, thunkApi) => {
    // console.log(date, "backend=>>>>>");
    const lang = localStorage.getItem("lang");
    const location = localStorage.getItem("location");
    try {
      const res = await axios.get(
        `${newBaseUrl}${lang}/api/Site/News/${date}/${location}`,

        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      return res.data;
    } catch (error) {
      console.log(error);
    }
  }
);

const NewsSlice = createSlice({
  name: "About",
  initialState: { News: [], loading: false, error: null },
  extraReducers: {
    [getNews.pending]: (state) => {
      state.loading = true;
      state.error = null;
      state.News = [];
    },
    [getNews.fulfilled]: (state, action) => {
      state.News = action.payload;
      state.loading = false;
      state.error = null;
    },
    [getNews.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.action;
    },
  },
});

export default NewsSlice.reducer;
