import React, { useCallback, useEffect } from "react";
import BlueBg from "../Components/BlueBg";
import AboutBanner from "../Components/AboutComponents/AboutBanner";
import AboutSection from "../Components/AboutComponents/AboutSection";
import { useDispatch, useSelector } from "react-redux";
import { getAboutUs } from "../api/aboutUsReducer";
import Loading from "../Components/Loading";
import { Helmet } from "react-helmet";
import { t } from "i18next";
const Aboutus = () => {
  const dispatch = useDispatch();
  const GetData = useCallback(() => {
    dispatch(getAboutUs());
  }, [dispatch]);
  useEffect(() => {
    GetData();
  }, [GetData]);
  const data = useSelector(
    (state) => state?.AboutSlice?.AboutUS?.aboutUs || []
  );
  const aboutLoading = useSelector((state) => state?.AboutSlice?.loading);
  //console.log(data);

  return (
    <div>
      <Helmet>
        <title>About</title>
        <meta name="description" content="About" />
        <meta property="og:title" content="About nardi About nardi " />
        <meta property="og:description" content="stove About stove." />
      </Helmet>
      <BlueBg />
      {aboutLoading ? (
        <div className=" w-full lg:w-[100vw]  flex items-center justify-center lg:pt-[25rem] h-[100vh]">
          <Loading
            type="spinningBubbles"
            color="#EA5C24"
            height={30}
            width={30}
          />
        </div>
      ) : (
        <div className="w-[100vw] lg:pt-[25rem]  flex flex-col   ">
          <AboutBanner data={data[0]?.business_description} />
          <AboutSection
            aboutLoading={aboutLoading}
            img={data[0]?.vision_image}
            title={t(`PROMISE`)}
            data={data[0]?.vision}
          />
          <AboutSection
            img={data[0]?.mission_image}
            reverse={true}
            title={t(`Our Values`)}
            data={data[0]?.mission}
          />
          <AboutSection
            img={data[0]?.goal_image}
            title={t(`Label`)}
            data={data[0]?.goal}
          />
        </div>
      )}
    </div>
  );
};

export default Aboutus;
