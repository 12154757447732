import React, { useCallback, useEffect } from "react";
import BlueBg from "../Components/BlueBg";
import ContactBody from "../Components/ContactComponents/ContactBody";
import { useDispatch, useSelector } from "react-redux";
import { getContactsLocations } from "../api/contactReducer";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
const Contact = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const GetLocations = useCallback(() => {
    dispatch(getContactsLocations());
  }, [dispatch]);
  useEffect(() => {
    GetLocations();
  }, [GetLocations]);
  const data = useSelector(
    (state) => state?.ContactSlice?.contact?.locations || []
  );
  const contactLoading = useSelector((state) => state?.ContactSlice?.loading);
  // console.log(data);
  return (
    <div>
      <Helmet>
        <title>Contact</title>
        <meta name="description" content="Contact" />
        <meta property="og:title" content="Contact nardi Contact nardi " />
        <meta property="og:description" content="stove Contact stove." />
      </Helmet>
      <BlueBg />
      <div className="w-[100vw] lg:pt-[27rem]   flex flex-col py-9">
        <ContactBody data={data} contactLoading={contactLoading} />
      </div>
    </div>
  );
};

export default Contact;
