import React from "react";
import { HiOutlineLocationMarker } from "react-icons/hi";
import { CiMobile3 } from "react-icons/ci";
import { AiOutlineMail } from "react-icons/ai";
import ContactIconItem from "./ContactIconItem";
const ContactIcons = ({ data }) => {
  // const data = [
  //   {
  //     icon: <HiOutlineLocationMarker className="text-2xl text-newOrange " />,
  //     info: "CPC Industrial Zone, 6th of October City, Giza, Egypt",
  //   },
  //   {
  //     icon: <CiMobile3 className="text-2xl text-newOrange " />,
  //     info: "+02 01000006504",
  //   },
  //   {
  //     icon: <AiOutlineMail className="text-2xl text-newOrange " />,
  //     info: "info@nardi-italy.com",
  //   },
  // ];
  //  console.log(data);
  return (
    <div className="lg:w-[40vw] flex-col lg:flex-row w-full flex items-center lg:items-start justify-start gap-4">
      <ContactIconItem
        data={data?.address}
        icon={<HiOutlineLocationMarker className="text-2xl text-newOrange " />}
      />
      <ContactIconItem
        data={data?.phone}
        icon={<CiMobile3 className="text-2xl text-newOrange " />}
      />
      <ContactIconItem
        data={data?.email}
        icon={<AiOutlineMail className="text-2xl text-newOrange " />}
      />
    </div>
  );
};

export default ContactIcons;
