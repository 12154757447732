import React, { useCallback, useEffect, useState } from "react";

import { MdPlayArrow } from "react-icons/md";
import { Link } from "react-router-dom";
import { getCategory } from "../api/categoryReducer";
import { useDispatch, useSelector } from "react-redux";
import { newBaseUrlImg } from "../Consts";
const HoverNavBar = ({ show, setShow, showLogo }) => {
  const dispatch = useDispatch();
  const fetchCetegory = useCallback(() => {
    dispatch(getCategory());
  }, [dispatch]);
  useEffect(() => {
    fetchCetegory();
  }, [fetchCetegory]);

  const categoryData = useSelector(
    (state) => state?.CategorySlice?.category?.resulte || []
  );
  const [activeIndex, setActiveIndex] = useState(0);
  const [activeMiniCat, setActiveMiniCat] = useState(null);
  const [activeSubItem, setActiveSubItem] = useState(null);

  const handleHover = (index) => {
    setActiveIndex(index);
    setActiveMiniCat(null);
    setActiveSubItem(null);
  };

  const handleMiniCatHover = (cat) => {
    setActiveMiniCat(cat);
    setActiveSubItem(null);
  };

  const handleSubItemHover = (item) => {
    setActiveSubItem(item);
  };

  const data = categoryData[activeIndex]?.children?.map((item, i) => item);
  // console.log(data);
  return (
    <div
      className={` ${
        showLogo ? " xl:w-[52vw]  lg:w-[74vw] " : "xl:w-[52vw]   lg:w-[60vw]"
      } h-[60vh] bg-white m-auto flex z-50`}
    >
      <div
        className={` ${
          showLogo
            ? "max-lg:w-[30vw] lg:w-[25vw] md:w-[24vw]"
            : "max-lg:w-[30vw] lg:w-[25vw] md:w-[24vw]"
        } flex flex-col  h-full`}
      >
        <div className="bg-newOrange w-full h-[15%]"></div>
        <div className="h-full bg-[#d8d8d8] flex items-center  flex-col overflow-auto">
          {categoryData.map((item, i) => (
            <div
              key={i}
              className={`flex w-full items-center gap-7 px-10 py-3 ${
                activeIndex === i ? "bg-custom-gradient2 " : ""
              }`}
              onMouseEnter={() => handleHover(i)}
            >
              <img
                src={`${newBaseUrlImg}${item.image}`}
                alt="catImg"
                className="w-8 h-8 object-contain"
              />
              <Link
                to={`/products/${item?.id}`}
                onClick={() => setShow(!show)}
                className={`${
                  activeIndex === i ? "text-newOrange" : ""
                } text-sm font-normal`}
              >
                {item.name}
              </Link>
            </div>
          ))}
        </div>
      </div>
      <div className="px-6 py-5">
        <div className="flex flex-col gap-4 w-[30vw]">
          <h2 className="text-xl font-medium">
            {categoryData[activeIndex]?.name}
          </h2>
          <p className="text-xs font-normal leading-5">
            {categoryData[activeIndex]?.description}
          </p>
          <div className="bg-[#d8d8d8] lg:w-[30vw] md:w-[42vw] m-auto h-[1px] rounded mt-5"></div>
        </div>
        <div>
          {categoryData[activeIndex]?.children?.length > 0 ? (
            <div className="flex flex-row items-start gap-16 pt-8">
              <div className="flex flex-col gap-3">
                {data?.map((cat, i) => (
                  <div
                    className="flex  items-center flex-row gap-3"
                    onMouseEnter={() => handleMiniCatHover(cat)}
                    key={i}
                  >
                    <MdPlayArrow className="text-[#d8d8d8] font-normal text-base" />
                    <p
                      className={`hover:text-newOrange ${
                        activeMiniCat === cat ? "text-newOrange" : ""
                      } text-base font-normal text-black`}
                    >
                      {cat?.name}
                    </p>
                  </div>
                ))}
              </div>
              <div className="w-[1px] h-[20vh] bg-[#d8d8d8]"></div>
              <div className="flex flex-col gap-3">
                {activeMiniCat?.children?.map((item, i) => (
                  <div
                    key={i}
                    className="flex flex-row items-center gap-2"
                    onMouseEnter={() => handleSubItemHover(item)}
                  >
                    <Link
                      to={`productsByCategory/${item.id}`}
                      className="flex items-center  gap-1"
                      onClick={() => setShow(!show)}
                    >
                      <MdPlayArrow className="text-[#d8d8d8] font-normal text-base" />
                      <p className="text-base font-normal text-black">
                        {item.name}
                      </p>
                    </Link>
                  </div>
                ))}
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default HoverNavBar;
