import React from "react";
import NewImg from "../../assets/newsing.png";
import { AiFillCloseCircle } from "react-icons/ai";
import { newBaseUrlImg } from "../../Consts";
import moment from "moment";

const NewsPopUp = ({ show, setShow, popUpData }) => {
  return (
    <div className="fixed top-0 left-0 lg:top-[15%] w-[100vw] lg:w-[70vw] px-3 py-8 lg:left-[15%] bg-white z-50 rounded-lg overflow-auto shadow-productBarShadow">
      <div className="absolute w-[101vw] h-[101vh]  -ml-4 bg-black opacity-40 z-10" />

      <div>
        <img
          src={`${newBaseUrlImg}${popUpData?.image}`}
          alt={NewImg}
          className="w-[100vw] h-[50vh] object-contain relative"
        />
        <button onClick={() => setShow(!show)}>
          <AiFillCloseCircle className="absolute right-5 text-xl top-10" />
        </button>
      </div>

      <div className="flex items-center w-full justify-between gap-3">
        <div className="flex flex-col items-center justify-center w-14 h-14 bg-newOrange rounded-xl">
          <p className="text-white text-base font-medium">
            {moment(popUpData?.created_at).format("DD")}
          </p>
          <p className="text-white text-base font-medium">
            {moment(popUpData?.created_at, "DD-MM-YYYY").format("MMM")}
          </p>
        </div>
        <p className="text-black font-medium text-sm w-[63vw]">
          {popUpData?.content}
        </p>
      </div>
    </div>
  );
};

export default NewsPopUp;
