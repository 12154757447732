import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { newBaseUrl } from "../Consts";
export const getProductById = createAsyncThunk(
  `product/getProductById`,
  async (id, thunkApi) => {
    try {
      const lang = localStorage.getItem("lang");
      const location = localStorage.getItem("location");

      // console.log(lang, "id");
      const res = await axios.get(
        `${newBaseUrl}${lang}/api/Site/product/${id}/${location}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      return res.data;
    } catch (error) {
      console.log(error);
    }
  }
);
const productByIdSlice = createSlice({
  name: "productId",
  initialState: { productId: [], loading: false, error: null },
  extraReducers: {
    [getProductById.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [getProductById.fulfilled]: (state, action) => {
      state.productId = action.payload;
      state.loading = false;
      state.error = null;
    },
    [getProductById.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.action;
    },
  },
});

export default productByIdSlice.reducer;
