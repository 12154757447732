import React from "react";

import ProducBodyBlockCat from "./ProducBodyBlockCat";
import { Helmet } from "react-helmet";
import { t } from "i18next";
const ProductBodyByCat = ({ show, setShow, productsData }) => {
  return (
    <div className="flex flex-col items-center justify-start pt-4 ">
      {/* <Helmet>
        <meta name="description" content={productsData?.products.seo} />
        <meta property="og:title" content={productsData?.products.name} />
        <meta
          property="og:description"
          content={productsData?.products.description}
        />
      </Helmet> */}
      <button
        className="bg-newOrange px-7 py-3 text-base rounded-lg text-white font-medium self-start  lg:hidden"
        onClick={() => setShow(!show)}
      >
        Filter
      </button>
      {productsData?.products?.length > 0 ? (
        <ProducBodyBlockCat data={productsData} />
      ) : (
        <div className=" w-[95vw] h-[75vh]  flex items-center justify-center lg:w-[75vw] lg:h-[80vh] ">
          <p className="text-3xl text-newOrange font-medium">{t("No Data")}</p>
        </div>
      )}
    </div>
  );
};

export default ProductBodyByCat;
