import React from "react";
import ContactCard from "./ContactCard";
import { useTranslation } from "react-i18next";

const ContactRight = ({ data }) => {
  console.log('data', data)
  const { t, i18n } = useTranslation();

  return (
    <div className="lg:w-[50vw] w-full flex items-center mt-[5vh] justify-center flex-col gap-8">
      <p className="font-medium text-sm text-[#818181] w-full text-center">
        {t("Office Locations and Contact Information:")}
      </p>
      <div className="flex flex-col gap-4 mt-[8vh]">
        {data?.map((item, i) => (
          <ContactCard key={i} itemData={item} />
        ))}
      </div>
    </div>
  );
};

export default ContactRight;
