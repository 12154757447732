import { configureStore } from "@reduxjs/toolkit";
import AboutSlice from "../api/aboutUsReducer";
import ContactSlice from "../api/contactReducer";
import NewsSlice from "./NewReducer";
import CategorySlice from "./categoryReducer";
import ProductsCatSlice from "./ProductsByCategory";
import ProductId from "./productById";
import subscribeSlice from "./subscibeReducer";
import LangReducer from "./LangReducer";
const store = configureStore({
  reducer: {
    AboutSlice,
    ContactSlice,
    NewsSlice,
    CategorySlice,
    ProductsCatSlice,
    ProductId,
    subscribeSlice,
    LangReducer,
  },
});
export default store;
