import React, { useState } from "react";
import { Link } from "react-router-dom";
import { newBaseUrlImg } from "../../Consts";
import { Helmet } from "react-helmet";

const ProductBodyBlockItem = ({ product }) => {
  const [show, setShow] = useState(false);
  // console.log(product, "slslssl");
  // {
  //     name: "GGR15112DX",
  //     desc: "Freestanding Stove & Oven (Fan Assisted Gas, 90cm)",}

  return (
    <>
      <Helmet>
        <meta name="description" content={product.seo} />
        <meta property="og:title" content={product.name} />
        <meta property="og:description" content={product.description} />
      </Helmet>
      <Link
        to={`/product/${product?.id}`}
        className="flex items-center justify-center md:w-[160px] lg:w-[245px] lg:h-[40vh] md:h-[20vh] h-[50vh]  gap-5 bg-white shadow-productBarShadow p-6 flex-col transition-all duration-1000 "
        onMouseEnter={() => setShow(true)}
        onMouseLeave={() => setShow(false)}
      >
        <img
          src={`${newBaseUrlImg}${product?.images[0]?.image}`}
          alt={product?.image}
          className={`${
            show
              ? "lg:w-[15vw] lg:h-[20vh] w-[40vw] h-[35vh] md:h-[10vh] md:w-[10vw]"
              : "lg:w-[20vw] lg:h-[31vh] w-[55vw]  h-[35vh] md:w-[15vw] md:h-[20vh]"
          } object-contain transition-all duration-300 `}
        />
        <div
          className=" felx items-center justify-center lg:w-[15vw] text-center  transition-all duration-1000 "
          hidden={show !== true}
        >
          <p className="lg:text-base text-sm  text-black font-medium transition-all  duration-1000">
            {product.name}
          </p>
          <span className="text-xs lg:text-sm text-[#777777] font-medium transition-all  duration-1000">
            {product?.description.substring(0, 25)}...
          </span>
        </div>
      </Link>
    </>
  );
};

export default ProductBodyBlockItem;
