import React from "react";
import ContactLeft from "./ContactLeft";
import ContactRight from "./ContactRight";
import Loading from "../Loading";
import { useTranslation } from "react-i18next";

const ContactBody = ({ data, contactLoading }) => {
  const { t, i18n } = useTranslation();

  return (
    <div className="w-[100vw] flex items-center justify-center  flex-col">
      <p className="text-3xl font-medium text-black">{t("Contact Us")}</p>
      {contactLoading ? (
        <div className=" w-full   flex items-center justify-center  h-[50vh]">
          <Loading
            type="spinningBubbles"
            color="#EA5C24"
            height={30}
            width={30}
          />
        </div>
      ) : (
        <div className="flex w-full items-start justify-center flex-col lg:flex-row ">
          <ContactLeft data={data[0]} />
          <ContactRight data={data} />
        </div>
      )}
    </div>
  );
};

export default ContactBody;
