import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import Backend from "i18next-http-backend";
i18next
  .use(initReactI18next)
  .use(LanguageDetector)
  .use(Backend)
  .init({
    fallbackLng: {
      fr: ["fr"],
      //"en-US": ["en"],
      //en: ["en"],
      //ar: ["ar", "ar"],
    },
  });

// // i18n.js
// import i18n from "i18next";
// import { initReactI18next } from "react-i18next";
// import Backend from "i18next-http-backend";
// import LanguageDetector from "i18next-browser-languagedetector";

// i18n
//   .use(Backend)
//   .use(LanguageDetector)
//   .use(initReactI18next)
//   .init({
//     //lng: 'fr',
//     fallbackLng: {
//       fr: ["fr"],
//       "en-US": ["en"],
//       "en-GB": ["en"],
//       ar: ["ar", "ar"],
//     },
//     debug: true,
//     interpolation: {
//       escapeValue: false,
//     },
//   });

// export default i18n;
