import { t } from "i18next";
import React, { useState } from "react";
import { MdArrowDropDown } from "react-icons/md";

const ProductDataWrapper = ({ item }) => {
  const [show, setShow] = useState(false);

  return (
    <div className="lg:w-[45vw] w-[90vw] m-auto flex items-center justify-center flex-col my-4 shadow-productBarShadow">
      <button
        className="w-full flex items-center justify-between shadow-productBarShadow bg-white py-4 px-4"
        onClick={() => setShow(!show)}
      >
        <p className="text-xl font-light text-black">{t(item?.name)}</p>
        <MdArrowDropDown className="text-[#D9D9D9] text-xl" />
      </button>
      {show && (
        <div
          className={`w-full ${
            show ? " h-[300px]" : ""
          } items-center justify-center overflow-auto`}
        >
          {item?.specifications?.map((item, i) => (
            <div
              key={i}
              className={`w-full grid grid-cols-2 ${
                i % 2 === 0 ? "bg-[#FFFFFF]" : "bg-[#EDEDED]"
              } p-2`}
            >
              <p className="md:text-base text-xs font-medium text-black">
                {item.key}
              </p>
              <p className="md:text-base text-xs font-medium text-[#8A8A8A]">
                {item.value}
              </p>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default ProductDataWrapper;
