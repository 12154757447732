import React from "react";

import ProducBodyBlock from "./ProducBodyBlock";
import Loading from "../Loading";
const ProductBody = ({ show, setShow, productsData, loadingProduct }) => {
  //console.log(productsData);
  return (
    <div className="flex flex-col items-center justify-start pt-4 ">
      <button
        className="bg-newOrange px-7 py-3 text-base rounded-lg text-white font-medium self-start  lg:hidden"
        onClick={() => setShow(!show)}
      >
        Filter
      </button>
      {loadingProduct ? (
        <div className=" w-screen h-[50vh] lg:w-[70vw] lg:h-full flex items-center justify-center">
          <Loading
            type="spinningBubbles"
            color="#EA5C24"
            height={30}
            width={30}
          />
        </div>
      ) : (
        productsData?.children?.map((item) =>
          item?.children?.map(
            (item2, i) =>
              item2?.products.length > 0 && (
                <ProducBodyBlock data={item2} key={i} />
              )
          )
        )
      )}
    </div>
  );
};

export default ProductBody;
