import React, { useState } from "react";
import Logo from "../assets/nardi-logo.png";
import { RiContactsBook2Line } from "react-icons/ri";
import { FiPhoneCall } from "react-icons/fi";
import { MdOutlineAlternateEmail } from "react-icons/md";
import { AiOutlineCalendar } from "react-icons/ai";
import { NavLink } from "react-router-dom";
import { subscirbeMail } from "../api/subscibeReducer";
import { useDispatch, useSelector } from "react-redux";
import Loading from "./Loading";
// import { getContactsLocations } from "../api/contactReducer";
import { t } from "i18next";
const Footer = () => {
  const dispatch = useDispatch();
  const data = useSelector(
    (state) => state?.ContactSlice?.contact?.locations || []
  );
  const [email, setEmail] = useState("");
  const handleSubscribe = () => {
    const data = {
      email,
    };
    dispatch(subscirbeMail(data));
  };
  const loading = useSelector((state) => state?.subscribeSlice?.loading);
  const ms = useSelector((state) => state?.subscribeSlice?.subscirbe?.Message);
  const links = [
    { name: t("About"), link: "/about" },
    { name: t("News"), link: "/news" },
    { name: t("Products"), link: "/products/1" },
  ];

  return (
    <div className="lg:h-[320px]  bg-gradient-to-b from-[#FF8A00] to-white flex items-end">
      <div className="lg:h-[300px] grid grid-cols-1 md:grid-cols-1 lg:grid-cols-3 p-4 lg:p-8 gap-6 align-middle place-content-center bg-white w-[97%] md:w-[98%] lg:w-[99%] mt-4">
        {/* logooo */}
        <div className="flex items-center lg:items-start md:items-center flex-col gap-4 m-auto lg:m-0 ">
          <img
            src={Logo}
            alt={Logo}
            className="w-[20rem] h-[5rem] object-contain "
          />
          <p className="text-sm font-normal text-gray-600 w-[80%] ">
            {t(
              "Combining technology and innovation, which inspires Italian quality."
            )}
          </p>
        </div>
        <div className="flex items-start md:justify-center md:px-10 justify-start flex-row">
          {/* Links */}
          <div className="flex flex-col gap-5 items-start   flex-1 ">
            <p className="text-newOrange text-xs md:text-base lg:text-xl font-semibold">
              {t("Quick Links")}
            </p>
            {links.map((link, i) => (
              <NavLink
                key={i}
                to={link.link}
                className="text-sm font-medium text-black"
              >
                {link.name}
              </NavLink>
            ))}
          </div>
          {/* Contact */}
          <div className="flex flex-col gap-4 items-start  flex-1 ">
            <p className="text-newOrange text-sm md:text-base lg:text-xl font-semibold m-auto">
              {t("Contact Info")}
            </p>

            <div className="flex items-center justify-start gap-3 ">
              <RiContactsBook2Line className="text-newOrange text-[40px] md:text-lg w-8 lg:text-2xl" />
              <p className="flex items-center text-xs font-medium gap-2">
                {data[0]?.address}
              </p>
            </div>
            <div className="flex items-center justify-start gap-3 ">
              <MdOutlineAlternateEmail className="text-newOrange text-[20px] md:text-lg lg:text-2xl" />
              <p
                className="flex items-center text-xs font-medium gap-2"
                style={{ direction: "ltr" }}
              >
                {data[0]?.website_url}
              </p>
            </div>
            <div className="flex items-center justify-start gap-3 ">
              <FiPhoneCall className="text-newOrange text-[20px] md:text-lg lg:text-2xl " />
              <p className="flex items-center text-xs font-medium gap-2">
                {data[0]?.phone}
              </p>
            </div>
            <div className="flex items-center justify-start gap-3 ">
              <AiOutlineCalendar className="text-newOrange text-[40px] md:text-lg lg:text-2xl " />
              <p className="flex items-center text-xs font-medium gap-2">
                {t("Sunday - Thursday / 8:00 - 17:00")}
              </p>
            </div>
          </div>
        </div>
        {/* Input */}
        <div className="flex items-start gap-2 m-auto flex-col">
          <div className="flex">
            <input
              type="text"
              placeholder={t("Enter You Email")}
              onChange={(e) => setEmail(e.target.value)}
              className="text-[#8D8D8D] text-base lg:text-lg border border-newOrange bg-white lg:px-4 md:px-3 px-1 py-2 rounded-md "
            />
            <button
              className="bg-newOrange  w-24 h-12 rounded-md -ml-4 text-white flex items-center justify-center"
              onClick={() => handleSubscribe()}
            >
              {loading ? (
                <Loading
                  type={"spinningBubbles"}
                  color={"#Ffffff"}
                  height={15}
                  width={15}
                />
              ) : (
                t("Subscibe")
              )}
            </button>
          </div>
          {ms && <p className="text-base text-green-700 font-normal">{ms}</p>}
        </div>
      </div>
    </div>
  );
};

export default Footer;
