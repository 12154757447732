import React from "react";
import NavBar from "../Components/NavBar";
import { Outlet } from "react-router-dom";
import NavBarMobile from "../Components/NavBarMobile";
import Footer from "../Components/Footer";
import PowerdBy from "../Components/PowerdBy";
import { Helmet } from "react-helmet";

const Layout = () => {
  return (
    <div className="overflow-hidden">
      <Helmet>
        <title>Nardi Website</title>
        <meta name="description" content="Stove" />
        <meta property="og:title" content="Nardi nardi nardi nardi " />
        <meta property="og:description" content="stove stove stove." />
      </Helmet>
      <div className="hidden lg:flex">
        <NavBar />
      </div>
      <div className="flex lg:hidden">
        <NavBarMobile />
      </div>
      <Outlet />
      <Footer />
      <PowerdBy />
    </div>
  );
};

export default Layout;
