import moment from "moment";
import React, { useCallback, useState } from "react";
import { useEffect } from "react";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { useDispatch } from "react-redux";
import { getNews } from "../../api/NewReducer";

const LeftCalender = ({ setSelectedDate }) => {
  const [value, onChange] = useState(new Date());
  const dateTime = moment(value, "ddd MMM DD YYYY HH:mm:ss [GMT]ZZ (z)").format(
    "YYYY-MM-DD"
  );
  const HandleSetData = useCallback(() => {
    setSelectedDate(dateTime);
  }, []);
  const dispatch = useDispatch();

  useEffect(() => {
    HandleSetData();
  }, []);
  const tileDisabled = ({ date }) => {
    return date > new Date(); // Disable future dates
  };
  const handleDateChange = (date) => {
    if (date > new Date()) {
      onChange(new Date());
    } else {
      onChange(date);
      dispatch(getNews(dateTime));
    }
  };
  return (
    <div className="h-[55vh] md:h-[35vh] mb-4  w-[70vw] md:w-[40vw] lg:w-[18vw] lg:h-[47.5vh] flex flex-col items-center justify-start rounded-lg ">
      <div className="bg-newOrange w-full md:h-[8vh] h-[10vh] lg:h-[15vh]  flex items-center justify-center rounded-t-lg">
        <p className="text-white text-lg font-medium">Filter</p>
      </div>
      <div className="w-full h-full z-50  ">
        <Calendar
          onChange={handleDateChange}
          value={value}
          tileDisabled={tileDisabled}
        />
      </div>
    </div>
  );
};

export default LeftCalender;
